import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
declare var $:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user:any={};
  constructor(
    private _toastr:ToastrService,
    private _router:Router,
    private _authService:AuthService
  ) {
    if(_authService.islogin()) this.user = _authService.getUser();

   }

  ngOnInit(): void {
  }

  toggleAside(){
      if($("#kt_quick_user").hasClass("offcanvas-on")){
        $("#kt_quick_user").removeClass('offcanvas-on')
      }else{
        $("#kt_quick_user").addClass('offcanvas-on')
      }
  }

  logout(){
    localStorage.clear();
    this._router.navigate(['/auth/login']);
  }

  setProfileImage(){
    let styles={
      'background-image':this.user.profileImage == null? `url(assets/media/users/300_3.jpg)` : `url(${this.user.profileImage})`
    }
    return styles;
  }

}
